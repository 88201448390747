import { observable, action, decorate } from "mobx"

class UiModel {
    constructor() {
        this.fileSelection = null
        this.fileFocus = null
        this.directorySelection = null
        this.directoryFocus = null
        this.startImage = null
        this.ext = null
        this.mainVideo = null
        this.selectionExt = null
        this.focusExt = null
        this.startExt = null
        this.mainVidPlaying = false
    }

    Select(dir, file, extension) {
        //console.log("select")
        this.selectionExt = extension
        if(file !== null && extension !== null) {
            //console.log(this.getExt(file))
            this.directorySelection = dir
            this.fileSelection = file
            //if(this.getExt(file) !== "md") {
            //}
            //else {
            //    this.fileSelection = this.fileFocus
            //}
            this.ext = this.selectionExt
        }
        else if(file === null && extension === null) {
            this.directorySelection = dir
            this.fileSelection = this.startImage
            this.ext = this.startExt
            //debugger
        }
        //this.setExt(this.fileSelection)
        //console.log(this.treeSelection)
    }

    Focus(dir, file, extension) {
        //if(file === "md") {
        //    //console.log(dir, this.directoryFocus)
        //    this.directoryFocus = dir
        //}
        //else {
        this.focusExt = extension
        this.fileFocus = file
        this.directoryFocus = dir
        if(extension !== null) {
            this.ext = this.focusExt
        }
        else if(this.selectionExt !== null && this.directoryFocus === null){
            this.ext = this.selectionExt
        }
        else {
            this.ext = this.startExt
        }
        //debugger
        //this.setExt(this.focusSelection)
        //this.setExt(this.fileFocus)
        //}
    }

    setStartImage(startImage, extension) {
        this.startImage = startImage
        this.startExt = extension
        //if(this.directorySelection !== this.directoryFocus && this.fileSelection === null) {
        if(this.directorySelection !== this.directoryFocus && this.directoryFocus !== null && this.fileFocus === null) {
            //debugger
            this.ext = this.startExt
        }
    }
    
    setMainVideo(video) {
        this.mainVideo = video
    }

    setMainVidPlaying(state) {
        this.mainVidPlaying = state
        console.log(this.mainVidPlaying)
    }
}

decorate(UiModel, {
    fileSelection: observable,
    mainVidPlaying: observable,
    mainVideo: observable,
    fileFocus: observable,
    directorySelection: observable,
    directoryFocus: observable,
    startImage: observable,
    ext: observable,
    setStartImage: action,
    setMainVideo: action,
    setMainVidPlaying: action,
    Select: action,
    Focus: action,
})
const UiStore = new UiModel()
export default UiStore

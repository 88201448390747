/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

//import theme stylings for react-mosaic
//import "./src/styles/pure-min.css"
import "./src/styles/base-min.css"
import "./src/styles/grids-min.css"
import "./src/styles/grids-responsive-min.css"
import "typeface-space-mono"
import "typeface-source-code-pro"
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import "./src/styles/index.less"
import "./src/styles/fileTree.less"
import "video.js/dist/video-js.min.css"
import "./src/styles/video.css"
import "./src/styles/metadata.css"
import "./src/styles/imagePanner.css"
import "./src/styles/typography.css"


//import the typefaces

//mobx Provider
import wrapWithProvider from './wrap-with-provider'

export const wrapRootElement = wrapWithProvider
